import { analyticsPath, authPath } from "@constants/base-urls";
import { PASSWORD_NOT_SELECTED } from "@constants/response-messages";
import { handleError, openCenteredWindow } from "@helpers/store-helper";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { getPermissionURL } from "src/helpers/getPermissionURL";
import { fetchAnalytics, fetchAnalyticsSeo } from "../analytics/action";
import { fetchBusiness } from "../reviews";
import { fetchSocialMedia } from "../social-media/action";

//Company
export const fetchCompanies = createAsyncThunk("appAuth/fetchCompanies", async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get(`${analyticsPath}/companies`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    if (!response?.data?.length) {
      window.location.replace("/onboarding/get-started");
    }

    return response?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectedCompany = createAsyncThunk(
  "appAnalytics/getSelectedCompany",
  async (onboardingForm, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`${analyticsPath}/companies/select`, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      if (response?.data?.id && onboardingForm) {
        dispatch(updateCompany({ ...onboardingForm, id: response?.data?.id }));
      }

      return response?.data;
    } catch (error) {
      if (error.response.data.statusCode === 403 || error.response.data.message === "Company not selected.") {
        onboardingForm && dispatch(createCompany({ formData: onboardingForm }));
      }

      return rejectWithValue(error);
    }
  }
);

export const createCompany = createAsyncThunk(
  "appAuth/createCompany",
  async ({ formData, isCreateCompanyModal = false }, { rejectWithValue, dispatch }) => {
    try {
      const data = {
        name: formData.company,
        type: formData?.businessType.toLowerCase(),
        field: formData?.business,
        phone: formData?.phone,
        city: formData?.city,
        state: formData?.state,
        address: formData?.address,
        url: formData?.url
      };

      const response = await axios.post(`${analyticsPath}/companies`, data, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      if (isCreateCompanyModal) {
        dispatch(fetchCompanies());
        dispatch(getSelectedCompany());
        toast.success("Company Created Successfully", {
          duration: 2000
        });
      }
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendContactForm = createAsyncThunk(
  "appAuth/createCompany",
  async (form, { rejectWithValue, dispatch }) => {
    try {
      const data = {
        fullName: form?.fullName,
        phoneNumber: form?.phone,
        ...(form?.company ? { companyName: form?.company } : {}),
        ...(form?.url ? { websiteUrl: form?.url } : {})
      };

      await axios.post(`${authPath}/leads/customer-email`, data, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      toast.success("Thank you for contacting us!", {
        duration: 2000
      });
    } catch (error) {
      const errorMessage = error.response.data.message;

      toast.error(typeof errorMessage === "string" ? errorMessage : errorMessage.join(", "), {
        duration: 2000
      });
    }
  }
);

export const updateCompany = createAsyncThunk("appAuth/updateCompany", async (formData, { rejectWithValue }) => {
  try {
    const data = {
      id: formData.id,
      name: formData.company,
      type: formData?.businessType.toLowerCase(),
      field: formData?.business,
      phone: formData?.contactNumber,
      city: formData?.city,
      state: formData?.state,
      address: formData?.address,
      url: formData?.url
    };

    const response = await axios.patch(`${analyticsPath}/companies`, data, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    return response?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const leaveCompany = createAsyncThunk("appAuth/leaveCompany", async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.delete(`${analyticsPath}/companies/leave`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    dispatch(fetchCompanies());

    return response?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const selectCompany = createAsyncThunk(
  "appAnalytics/selectCompany",
  async ({ id, isSelectCompany }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${analyticsPath}/companies/select`,
        { id },
        {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        }
      );

      if (response?.data) {
        const roles = await axios.get(`${analyticsPath}/roles/my`, {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        });

        roles?.data && dispatch(fetchListOfStatusAccounts({ isSelectCompany, rolesData: roles?.data }));
      }

      dispatch(getSelectedCompany());

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Profile
export const createProfile = createAsyncThunk(
  "appProfile/createProfile",
  async ({ formData, file, isAccountSettings }, { rejectWithValue, dispatch }) => {
    try {
      const data = {
        firstName: formData?.firstName,
        lastName: formData?.lastName,
        position: formData?.position,
        phone: formData?.phone,
        email: formData?.email
      };

      const response = await axios.put(
        `${authPath}/profile`,
        { ...data },
        {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        }
      );

      file && dispatch(uploadProfileImage(file));

      isAccountSettings && toast.success("Profile Updated Successfully");
      dispatch(fetchProfile());

      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message[0]);
    }
  }
);

export const fetchProfile = createAsyncThunk("appProfile/fetchProfile", async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get(`${authPath}/profile`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const uploadProfileImage = createAsyncThunk(
  "appProfile/uploadProfileImage",
  async (file, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${authPath}/profile/image`,
        { image: file },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data"
          },
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteProfileImage = createAsyncThunk(
  "appProfile/deleteProfileImage",
  async (file, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(`${authPath}/profile/image`, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      toast.success("Profile Image Deleted Successfully");

      dispatch(fetchProfile());

      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

//Connections
export const fetchListOfStatusAccounts = createAsyncThunk(
  "appAnalytics/fetchListOfStatusAccounts",
  async ({ isSelectCompany, rolesData }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`${analyticsPath}/accounts`, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      if (isSelectCompany && response?.data) {
        const allFalse = Object.values(response.data).every(value => value === false);
        const redirectURL =
          rolesData.roleName === "Admin" ? "/onboarding/details/" : getPermissionURL(rolesData.permissions);

        window.location.replace(allFalse ? redirectURL : getPermissionURL(rolesData.permissions));
      }

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const connectAnalytics = createAsyncThunk("appAnalytics/connectAnalytics", async (data, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${analyticsPath}/accounts/google/analytics`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    if (data?.type === "accountSettings") {
      window.localStorage.setItem("accountSettings", JSON.stringify(true));
    } else {
      window.localStorage.removeItem("accountSettings");
    }

    response.data.url && openCenteredWindow(response.data.url, "centeredWindow", 1200, 700);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const connectSeo = createAsyncThunk("appAnalytics/connectSeo", async (data, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${analyticsPath}/accounts/google/seo`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    if (data?.type === "accountSettings") {
      window.localStorage.setItem("accountSettings", JSON.stringify(true));
    } else {
      window.localStorage.removeItem("accountSettings");
    }

    response.data.url && openCenteredWindow(response.data.url, "centeredWindow", 1200, 700);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const connectFacebook = createAsyncThunk("appAnalytics/connectAnalytics", async (data, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${analyticsPath}/accounts/facebook/social-media`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    openCenteredWindow(`${analyticsPath}/accounts/facebook/social-media`, "centeredWindow", 1200, 700);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const connectAccount = createAsyncThunk(
  "appAnalytics/connectAccount",
  async (code, { rejectWithValue, dispatch }) => {
    try {
      await axios.put(
        `${analyticsPath}/accounts/google?code=${encodeURIComponent(code)}`,
        {},
        {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        }
      );

      dispatch(fetchListOfStatusAccounts({}));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const connectFacebookAccount = createAsyncThunk(
  "appFacebook/connectFacebookAccount",
  async (code, { rejectWithValue, dispatch }) => {
    try {
      await axios.put(
        `${analyticsPath}/accounts/facebook?code=${encodeURIComponent(code)}`,
        {},
        {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        }
      );

      dispatch(fetchListOfStatusAccounts({}));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const connectFakeAccount = createAsyncThunk(
  "appAnalytics/connectAccount",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await axios.put(
        `${analyticsPath}/accounts/google?code=demo`,
        {},
        {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        }
      );

      dispatch(fetchListOfStatusAccounts({}));
      window.opener.location.reload();
      window.close();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const connectFbFakeAccount = createAsyncThunk(
  "appAnalytics/connectFacebookAccount",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await axios.put(
        `${analyticsPath}/accounts/facebook?code=demo`,
        {},
        {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        }
      );

      dispatch(fetchListOfStatusAccounts({}));
      window.opener.location.reload();
      window.close();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const connectBusiness = createAsyncThunk("appBusiness/connectBusiness", async (data, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${analyticsPath}/accounts/google/reviews`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    if (data?.type === "accountSettings") {
      window.localStorage.setItem("accountSettings", JSON.stringify(true));
    } else {
      window.localStorage.removeItem("accountSettings");
    }

    response.data.url && openCenteredWindow(response.data.url, "centeredWindow", 1200, 700);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const connectGoogleAds = createAsyncThunk("appBusiness/connectBusiness", async (data, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${analyticsPath}/accounts/google/ads`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    if (data?.type === "accountSettings") {
      window.localStorage.setItem("accountSettings", JSON.stringify(true));
    } else {
      window.localStorage.removeItem("accountSettings");
    }

    response.data.url && openCenteredWindow(response.data.url, "centeredWindow", 1200, 700);
  } catch (error) {
    return rejectWithValue(error);
  }
});

//Accounts
export const getListMyAccounts = createAsyncThunk(
  "appAccounts/getListMyAccounts",
  async (type, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${analyticsPath}/accounts/type/${type}`, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  "appAccounts/updateUserPassword",
  async (formData, { rejectWithValue }) => {
    try {
      const data = {
        password: formData?.currentPassword,
        newPassword: formData?.newPassword
      };

      const response = await axios.patch(`${authPath}/users/password`, data, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      toast.success("Password Changed Successfully");

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteMyGoogleAccounts = createAsyncThunk(
  "appAccounts/deleteMyGoogleAccounts",
  async ({ type, id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(`${analyticsPath}/accounts/type/${type}/${id}`, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      dispatch(fetchListOfStatusAccounts({}));
      dispatch(fetchAnalyticsSeo({ date: new Date() }));
      dispatch(fetchAnalytics({ date: new Date() }));

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const disconnectConnection = createAsyncThunk(
  "appAccounts/deleteMyGoogleAccounts",
  async ({ type, id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(`${analyticsPath}/accounts/type/${type}`, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      dispatch(fetchListOfStatusAccounts({}));
      dispatch(fetchAnalyticsSeo({ date: new Date() }));
      dispatch(fetchAnalytics({ date: new Date() }));

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Properties
export const fetchAccountProperties = createAsyncThunk(
  "appAnalytics/fetchAccountProperties",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`${analyticsPath}/analytics/properties`, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const selectProperties = createAsyncThunk(
  "appAnalytics/fetchAccountProperties",
  async ({ propertyId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${analyticsPath}/analytics/properties`,
        { propertyId },
        {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        }
      );

      const data = response.data;

      if (data.propertyId) {
        dispatch(fetchAccountProperties());
        // dispatch(fetchAnalytics({ ...response.data, date }));
      }
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Pages
export const getListMyPages = createAsyncThunk("appSocialMedia/getListMyPages", async (data, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${analyticsPath}/social-media/pages`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectedPage = createAsyncThunk("appSocialMedia/getSelectedPage", async (data, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${analyticsPath}/social-media/pages/select`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const selectPage = createAsyncThunk(
  "appSocialMedia/selectPage",
  async ({ accountId, facebookPageId, date, type }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${analyticsPath}/social-media/pages?type=${type}`,
        { id: accountId },
        {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        }
      );

      const data = response.data;

      if (data) {
        dispatch(getListMyPages());
        dispatch(fetchSocialMedia({ date }));
        // dispatch(getSelectedPage());
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Locations

export const fetchBusinessLocations = createAsyncThunk(
  "appBusiness/fetchBusinessLocations",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${analyticsPath}/reviews/locations`, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const selectLocations = createAsyncThunk(
  "appBusiness/selectLocations",
  async ({ accountId, locationId, date }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${analyticsPath}/reviews/locations`,
        { locationId },
        {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        }
      );

      const data = response.data;

      if (data.locationId) {
        dispatch(fetchBusinessLocations());
        dispatch(fetchBusiness({ date }));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Notifications
export const getNotifications = createAsyncThunk("appAccounts/getNotifications", async (data, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${authPath}/notifications/my`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    return response?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateNotifications = createAsyncThunk(
  "appAccounts/updateNotifications",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(`${authPath}/notifications/my`, data, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      toast.success("Notifications updated Successfully");

      dispatch(getNotifications());

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Devices
export const getRecentDevices = createAsyncThunk("appAccounts/getRecentDevices", async (data, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${authPath}/users/me/recent-devices`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    return response?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getMe = createAsyncThunk("appAccounts/getMe", async (data, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${authPath}/users/me`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    return response?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const setError = (state, action) => {
  state.status = false;
  state.isPagesLoading = false;
  state.isLoading = false;
  state.isCompanyLoading = false;
  state.isAccountConnected = false;
  state.isCompanyUpdatedSuccess = false;
  state.isCompanyCreatedSuccess = false;
  state.isAccountLoading = false;
  state.connectionLoading = false;
  state.isUpdatePasswordSuccess = false;
  state.redirectUrl = "";

  const message = action?.payload?.response?.data?.message;
  const statusCode = action?.payload?.response?.data?.statusCode;

  state.errorMessage = message;

  handleError(message, statusCode);
};

const setErrorGetProfile = (state, action) => {
  state.status = false;
  state.isLoadingProfile = false;
  state.isCreateProfileSuccess = false;
  state.isLoadingCreateProfile = false;

  state.error = action?.payload?.response?.data?.message;

  if (
    action?.payload?.response?.data?.statusCode !== 404 &&
    action?.payload?.response?.data?.message !== PASSWORD_NOT_SELECTED
  ) {
    toast.error(action?.payload?.response?.data?.message, {
      duration: 2000
    });
  }
};

export const appAuthSlice = createSlice({
  name: "appAuth",
  initialState: {
    error: "",
    redirectUrl: "",
    selectedCompany: {},
    selectedPage: {},
    companies: [],
    accounts: {},
    profile: {},
    properties: [],
    locations: [],
    googleAccounts: [],
    recentDevices: [],
    notifications: [],
    primaryProperties: {},
    me: {},
    isUpdatePasswordSuccess: false,
    isNotificationLoading: false,
    isAccountConnected: false,
    isCompanyUpdatedSuccess: false,
    isAccountLoading: true,
    isLoadingCreateProfile: true,
    isLoadingProfile: true,
    isPagesLoading: false,
    connectionLoading: false,
    isCreateProfileSuccess: false,
    isCompanyCreatedSuccess: false,
    isCompanyLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = "";
        state.companies = action.payload;
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        state.error = "";
        state.isLoading = false;
        state.companies = action.payload;
        state.isCompanyCreatedSuccess = true;
      })
      .addCase(createCompany.pending, (state, action) => {
        state.error = "";
        state.isLoading = true;
        state.isCompanyUpdatedSuccess = false;
        state.isCompanyCreatedSuccess = false;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.error = "";
        state.isLoading = false;
        state.isCompanyUpdatedSuccess = true;
        state.companies = action.payload;
      })
      .addCase(updateCompany.pending, (state, action) => {
        state.error = "";
        state.isLoading = true;
        state.isCompanyUpdatedSuccess = false;
      })
      .addCase(getSelectedCompany.fulfilled, (state, action) => {
        state.error = "";
        state.isLoading = false;
        state.isCompanyLoading = false;
        state.isCompanyUpdatedSuccess = false;
        state.selectedCompany = action.payload;
      })
      .addCase(getSelectedPage.fulfilled, (state, action) => {
        state.error = "";
        state.selectedPage = action.payload;
      })
      .addCase(getSelectedCompany.pending, (state, action) => {
        state.error = "";
        state.isLoading = true;
        state.isCompanyLoading = true;
        state.isCompanyUpdatedSuccess = false;
      })
      .addCase(createProfile.fulfilled, (state, action) => {
        state.error = "";
        state.isLoadingCreateProfile = false;
        state.isCreateProfileSuccess = true;
      })
      .addCase(createProfile.pending, (state, action) => {
        state.error = "";
        state.isLoadingCreateProfile = true;
        state.isCreateProfileSuccess = false;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.error = "";
        state.isLoadingProfile = false;
        state.isCreateProfileSuccess = false;
        state.profile = action.payload;
      })
      .addCase(fetchProfile.pending, (state, action) => {
        state.error = "";
        state.isLoadingProfile = true;
      })
      .addCase(connectAccount.fulfilled, (state, action) => {
        state.error = "";
        state.isAccountConnected = true;
      })
      .addCase(connectAccount.pending, (state, action) => {
        state.error = "";
        state.isAccountConnected = false;
      })
      .addCase(connectSeo.fulfilled, (state, action) => {
        state.error = "";
        state.redirectUrl = action.payload;
      })
      .addCase(connectSeo.pending, (state, action) => {
        state.error = "";
        state.redirectUrl = "";
      })
      .addCase(connectAnalytics.fulfilled, (state, action) => {
        state.error = "";
        state.redirectUrl = action.payload;
      })
      .addCase(connectAnalytics.pending, (state, action) => {
        state.error = "";
        state.redirectUrl = "";
        state.isLoading = true;
      })
      .addCase(connectFacebookAccount.fulfilled, (state, action) => {
        state.error = "";
        state.isAccountConnected = true;
        state.connectionLoading = false;
      })
      .addCase(connectFacebookAccount.pending, (state, action) => {
        state.error = "";
        state.isAccountConnected = false;
        state.connectionLoading = true;
      })
      .addCase(connectBusiness.fulfilled, (state, action) => {
        state.redirectUrl = action.payload;
      })
      .addCase(connectBusiness.pending, (state, action) => {
        state.isLoading = true;
        state.redirectUrl = "";
      })
      .addCase(getListMyAccounts.fulfilled, (state, action) => {
        state.error = "";
        state.googleAccounts = action.payload;
      })
      .addCase(fetchAccountProperties.fulfilled, (state, action) => {
        state.error = "";
        state.isLoading = false;
        state.properties = [action.payload];
      })
      .addCase(fetchListOfStatusAccounts.fulfilled, (state, action) => {
        state.error = "";
        state.isAccountLoading = false;
        state.analyticsError = false;
        state.accounts = action.payload;
      })
      .addCase(fetchListOfStatusAccounts.pending, (state, action) => {
        state.error = "";
        state.isAccountLoading = true;
        state.analyticsError = false;
      })
      .addCase(updateUserPassword.fulfilled, (state, action) => {
        state.error = "";
        state.isUpdatePasswordSuccess = true;
      })
      .addCase(updateUserPassword.pending, (state, action) => {
        state.error = "";
        state.isUpdatePasswordSuccess = false;
      })
      .addCase(fetchListOfStatusAccounts.rejected, (state, action) => {
        state.status = false;
        state.isLoading = false;
        state.isAccountLoading = false;
        state.isAccountConnected = false;
        state.isCompanyUpdatedSuccess = false;
        state.errorMessage = action?.payload?.message;
      })
      .addCase(fetchBusinessLocations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.locations = [action.payload];
      })
      .addCase(fetchBusinessLocations.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.status = true;
        state.isNotificationLoading = false;
        state.notifications = action.payload;
      })
      .addCase(getNotifications.pending, (state, action) => {
        state.status = false;
        state.isNotificationLoading = false;
        state.isNotificationLoading = true;
      })
      .addCase(getRecentDevices.fulfilled, (state, action) => {
        state.status = true;
        state.recentDevices = action.payload;
      })
      .addCase(getRecentDevices.pending, (state, action) => {
        state.status = false;
      })
      .addCase(getListMyPages.fulfilled, (state, action) => {
        state.pages = [action.payload];
        state.isPagesLoading = false;
      })
      .addCase(getListMyPages.pending, (state, action) => {
        state.isPagesLoading = true;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.me = action.payload;
        state.isPagesLoading = false;
      })
      .addCase(fetchCompanies.rejected, setError)
      .addCase(createCompany.rejected, setError)
      .addCase(selectCompany.rejected, setError)
      .addCase(updateCompany.rejected, setError)
      .addCase(fetchProfile.rejected, setErrorGetProfile)
      .addCase(createProfile.rejected, setError)
      .addCase(getSelectedCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.isCompanyLoading = false;
      })
      .addCase(connectAnalytics.rejected, setError)
      .addCase(connectSeo.rejected, setError)
      .addCase(fetchAccountProperties.rejected, setError)
      .addCase(connectAccount.rejected, setError)
      .addCase(connectFacebookAccount.rejected, setError)
      .addCase(connectBusiness.rejected, setError)
      .addCase(fetchBusinessLocations.rejected, setError)
      .addCase(getListMyAccounts.rejected, setError)
      .addCase(deleteMyGoogleAccounts.rejected, setError)
      .addCase(updateUserPassword.rejected, setError)
      .addCase(getNotifications.rejected, setError)
      .addCase(getRecentDevices.rejected, setError)
      .addCase(selectLocations.rejected, setError)
      .addCase(getListMyPages.rejected, setError)
      .addCase(getSelectedPage.rejected, setError)
      .addCase(selectPage.rejected, setError)
      .addCase(leaveCompany.rejected, setError);
  }
});

export default appAuthSlice.reducer;
