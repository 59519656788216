import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { DIFF_UNKNOWN, operationalTypes } from "@constants/types-constants";
import { analyticsPath } from "@constants/base-urls";
import { handleError } from "@helpers/store-helper";
import moment from "moment";

export const getOperational = createAsyncThunk(
  "appAnalytics/getOperational",
  async (type = "period", { rejectWithValue }) => {
    try {
      const response = await axios.get(`${analyticsPath}/operationals/data-entries?type=${type}`, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOperationalDashboard = createAsyncThunk(
  "appAnalytics/getOperationalDashboard",
  async ({ date = { start: 0, end: 0 } }, { rejectWithValue }) => {
    try {
      const { start, end } = date;
      const utcStart = start ? moment(start).utc().format("YYYY-MM-DD") : "";
      const utcEnd = end ? moment(end).utc().format("YYYY-MM-DD") : utcStart ? utcStart : "";
      const startDate = start ? `&startDate=${utcStart}` : "";
      const endDate = end ? `&endDate=${utcEnd}` : utcStart ? `&endDate=${utcStart}` : "";

      if (start || end) {
        const response = await axios.get(
          `${analyticsPath}/operationals?${startDate}${endDate}&data[]=appointments&data[]=patients&data[]=productions&data[]=collections`,
          {
            withCredentials: true,
            adapter: ["xhr", "http", function myCustomAdapter(config) {}]
          }
        );

        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createOperational = createAsyncThunk(
  "appAnalytics/createOperational",
  async ({ data, type = "period" }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${analyticsPath}/operationals/data-entries?type=${type}`, data, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const setOperationalData = (state, action) => {
  state.isOperationalLoading = false;
  state.getOperationalDashboardSuccess = false;
  state.isFiltersLoading = false;
  state.operationalDashboard = action.payload;

  state.appointments = action?.payload?.reduce((result, item) => {
    if (item.header === operationalTypes.APPOINTMENTS) {
      result.difference = item.diff === DIFF_UNKNOWN || isFinite(item.diff) ? "" : item.diff;
      result.total = item.total;
      result.graphs = item.graphs;
      result.reports = item.reports;
    }
    return result;
  }, {});

  state.patients = action?.payload?.reduce((result, item) => {
    if (item.header === operationalTypes.PATIENTS) {
      result.difference = item.diff === DIFF_UNKNOWN || isFinite(item.diff) ? "" : item.diff;
      result.total = item.total;
      result.graphs = item.graphs;
      result.reports = item.reports;
    }
    return result;
  }, {});

  state.allProviders = action?.payload?.reduce((result, item) => {
    if (item.header === operationalTypes.ALL_PROVIDERS) {
      result.difference = item.diff === DIFF_UNKNOWN || isFinite(item.diff) ? "" : item.diff;
      result.total = item.total;
      result.graphs = item.graphs;
      result.reports = item.reports;
    }
    return result;
  }, {});

  state.activeProviders = action?.payload?.reduce((result, item) => {
    if (item.header === operationalTypes.ACTIVE_PROVIDERS) {
      result.difference = item.diff === DIFF_UNKNOWN || isFinite(item.diff) ? "" : item.diff;
      result.total = item.total;
      result.graphs = item.graphs;
      result.reports = item.reports;
    }
    return result;
  }, {});

  state.deactivatedProviders = action?.payload?.reduce((result, item) => {
    if (item.header === operationalTypes.DEACTIVATED_PROVIDERS) {
      result.difference = item.diff === DIFF_UNKNOWN || isFinite(item.diff) ? "" : item.diff;
      result.total = item.total;
      result.graphs = item.graphs;
      result.reports = item.reports;
    }
    return result;
  }, {});

  state.collections = action?.payload?.reduce((result, item) => {
    if (item.header === operationalTypes.COLLECTIONS) {
      result.difference = item.diff === DIFF_UNKNOWN || isFinite(item.diff) ? "" : item.diff;
      result.total = item.total;
      result.graphs = item.graphs;
      result.reports = item.reports;
    }
    return result;
  }, {});
};

const setError = (state, action) => {
  // console.log('reducer state', action?.payload);
  if (action.payload.response.status !== 400) {
    state.getOperationalSuccess = false;
    state.getOperationalDashboardSuccess = false;
    state.isFiltersLoading = false;
    state.isOperationalLoading = false;

    const message = action?.payload?.response?.data?.message;
    const statusCode = action?.payload?.response?.data?.statusCode;

    state.errorMessage = message;

    if (message !== "No data for the selected period.") {
      handleError(message, statusCode);
    }
  } else if (action.payload.response.status === 400) {
    state.getOperationalSuccess = false;
    state.getOperationalDashboardSuccess = false;
    state.isFiltersLoading = false;
    state.isOperationalLoading = false;
  }
};

const setErrorCreateProvider = (state, action) => {
  state.createOperationalSuccess = false;
  state.getOperationalDashboardSuccess = false;
  const message = action?.payload?.response?.data?.message;
  const statusCode = action?.payload?.response?.data?.statusCode;

  state.providersErrorMessage = message;

  state.errorMessage = message;

  if (message !== "No data for the selected period.") {
    handleError(message, statusCode);
  }
};

export const appOperationalSlice = createSlice({
  name: "appOperational",
  initialState: {
    dataEntries: [],
    operationalDashboard: [],
    appointments: [],
    collections: [],
    patients: [],
    allProviders: [],
    activeProviders: [],
    deactivatedProviders: [],
    warehouse: false,
    createOperationalSuccess: false,
    getOperationalSuccess: false,
    isOperationalFiltersLoading: false,
    isFiltersLoading: false,
    getOperationalDashboardSuccess: false,
    providersError: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getOperationalDashboard.fulfilled, setOperationalData)
      .addCase(getOperationalDashboard.pending, (state, action) => {
        state.getOperationalDashboardSuccess = false;
        state.isOperationalLoading = true;
        state.isFiltersLoading = true;
      })
      .addCase(getOperationalDashboard.rejected, setError)
      .addCase(getOperational.fulfilled, (state, action) => {
        state.getOperationalSuccess = true;
        state.dataEntries = action.payload;
        state.warehouse = action.payload.warehouse;
      })
      .addCase(getOperational.pending, (state, action) => {
        state.getOperationalSuccess = false;
      })
      .addCase(getOperational.rejected, setError)
      .addCase(createOperational.fulfilled, (state, action) => {
        state.createOperationalSuccess = true;
      })
      .addCase(createOperational.pending, (state, action) => {
        state.createOperationalSuccess = false;
      })
      .addCase(createOperational.rejected, setErrorCreateProvider);
  }
});

export default appOperationalSlice.reducer;
