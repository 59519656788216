import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { analyticsPath } from "@constants/base-urls";
import toast from "react-hot-toast";
import {
  ADDED_PROVIDER_SUCCESS_TITLE,
  ADDED_SUCCESS_TEXT,
  ADDED_SUCCESS_TITLE,
  DELETED_PROVIDER_SUCCESS_TITLE,
  ROLES_ADDED_SUCCESS_TITLE,
  ROLES_SUCCESS_TITLE,
  UPDATED_PROVIDER_SUCCESS_TITLE,
  UPDATED_SUCCESS_TEXT,
  UPDATED_SUCCESS_TITLE,
  DELETED_SUCCESS_TEXT,
  DELETED_SUCCESS_TITLE,
  ROLE_DELETED_SUCCESS_TITLE
} from "@constants/text";
import { PASSWORD_NOT_SELECTED } from "@constants/response-messages";
import SuccessMessage from "../../../views/components/toast/SuccessMessage";

export const createRole = createAsyncThunk("appRoles/createRole", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(`${analyticsPath}/roles`, data, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    dispatch(fetchRolesList());

    toast.success(ROLES_ADDED_SUCCESS_TITLE, {
      duration: 2000
    });

    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getRoleByName = createAsyncThunk("appRoles/getRoleByName", async (name, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get(`${analyticsPath}/roles/${name}`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getMyRole = createAsyncThunk("appRoles/getMyRole", async (name, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get(`${analyticsPath}/roles/my`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteRoleByName = createAsyncThunk(
  "appRoles/deleteRoleByName",
  async (name, { rejectWithValue, dispatch }) => {
    try {
      await axios.delete(`${analyticsPath}/roles/${name}`, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      dispatch(fetchRolesList());

      toast.success(ROLE_DELETED_SUCCESS_TITLE, {
        duration: 2000
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRoleByName = createAsyncThunk(
  "appRoles/updateRoleByName",
  async ({ data, name }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(`${analyticsPath}/roles/${name}`, data, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      dispatch(fetchRolesList());

      toast.success(ROLES_SUCCESS_TITLE, {
        duration: 2000
      });

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchRolesList = createAsyncThunk("appRoles/fetchRolesList", async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get(`${analyticsPath}/roles`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    return response?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchRolesPermissions = createAsyncThunk(
  "appRoles/fetchRolesPermissions",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`${analyticsPath}/roles/permissions`, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Providers
export const getProviders = createAsyncThunk("appAnalytics/getProviders", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${analyticsPath}/providers`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createProvider = createAsyncThunk(
  "appAnalytics/createProvider",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`${analyticsPath}/providers`, data, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      dispatch(getProviders());
      toast(t => <SuccessMessage close={() => toast.dismiss(t.id)} title={ADDED_PROVIDER_SUCCESS_TITLE} />);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProvider = createAsyncThunk(
  "appAnalytics/updateProvider",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(`${analyticsPath}/providers/${id}`, data, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      dispatch(getProviders());
      toast(t => <SuccessMessage close={() => toast.dismiss(t.id)} title={UPDATED_PROVIDER_SUCCESS_TITLE} />);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProvider = createAsyncThunk(
  "appAnalytics/deleteProvider",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(`${analyticsPath}/providers/${id}`, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      dispatch(getProviders());
      toast(t => <SuccessMessage close={() => toast.dismiss(t.id)} title={DELETED_PROVIDER_SUCCESS_TITLE} />);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const invitePersonnel = createAsyncThunk(
  "appPersonnel/invitePersonnel",
  async ({ email, role, position, reportOrder }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${analyticsPath}/personnel/invite`,
        {
          email: email?.toLowerCase(),
          role: role,
          position: position,
          reportOrder: reportOrder
        },
        {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        }
      );

      toast(t => (
        <SuccessMessage close={() => toast.dismiss(t.id)} title={ADDED_SUCCESS_TITLE} text={ADDED_SUCCESS_TEXT} />
      ));

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchPersonnelList = createAsyncThunk(
  "appPersonnel/fetchPersonnelList",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`${analyticsPath}/personnel`, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const confirmInvite = createAsyncThunk(
  "appPersonnel/confirmInvite",
  async (code, { rejectWithValue, dispatch }) => {
    try {
      await axios.put(
        `${analyticsPath}/personnel/confirm-invite/${encodeURIComponent(code)}`,
        {},
        {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        }
      );

      window.location.replace("/account-settings/account/");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePerson = createAsyncThunk(
  "appPersonnel/updatePerson",
  async ({ formData, id, myRole, myEmail }, { rejectWithValue, dispatch }) => {
    try {
      const data =
        myEmail === formData.email
          ? {
              active: formData?.active,
              position: formData?.position,
              ...(formData.role !== myRole?.roleName ? { role: formData.role } : {})
            }
          : formData;

      const response = await axios.patch(`${analyticsPath}/personnel/${id}`, data, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      });

      dispatch(fetchPersonnelList());

      toast(t => (
        <SuccessMessage close={() => toast.dismiss(t.id)} title={UPDATED_SUCCESS_TITLE} text={UPDATED_SUCCESS_TEXT} />
      ));

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deletePersonnel = createAsyncThunk("appRoles/deleteRole", async (id, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.delete(`${analyticsPath}/personnel/${id}`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });

    dispatch(fetchPersonnelList());

    toast(t => (
      <SuccessMessage close={() => toast.dismiss(t.id)} title={DELETED_SUCCESS_TITLE} text={DELETED_SUCCESS_TEXT} />
    ));

    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const setPermissions = (state, action) => {
  state.permissions = action.payload;
  state.newRolePermissions = {
    roleName: "",
    permissions: action.payload.map(permissionName => ({
      permissionName,
      read: false,
      write: false
    }))
  };
};

const setError = (state, action) => {
  state.status = false;
  state.isRoleLoading = false;
  state.createProviderSuccess = false;
  state.updateProviderSuccess = false;
  state.deleteProviderSuccess = false;
  state.isPersonnelLoading = false;
  state.invitePersonnelSuccess = false;
  state.updatePersonSuccess = false;
  state.deletePersonSuccess = false;
  state.error = action.payload?.response?.data?.message;

  if (action.payload?.response?.data?.message !== PASSWORD_NOT_SELECTED) {
    toast.error(action.payload?.response?.data?.message, {
      duration: 2000
    });
  }
};

export const appRoles = createSlice({
  name: "appRoles",
  initialState: {
    role: {},
    roles: [],
    providers: [],
    createProviderSuccess: false,
    updateProviderSuccess: false,
    deleteProviderSuccess: false,
    myRole: { role: "", permissions: [] },
    permissions: [],
    personnel: [],
    isPersonnelLoading: false,
    status: null,
    invitePersonnelSuccess: false,
    updatePersonSuccess: false,
    deletePersonSuccess: false,
    newRolePermissions: {
      roleName: "",
      permissions: [
        {
          permissionName: "",
          read: false,
          write: false
        }
      ]
    },
    isRoleLoading: false,
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRoleByName.fulfilled, (state, action) => {
        state.role = action.payload;
        state.isRoleLoading = false;
        state.error = false;
      })
      .addCase(getMyRole.fulfilled, (state, action) => {
        state.myRole = action.payload;
        state.isRoleLoading = false;
        state.error = false;
      })
      .addCase(fetchRolesList.fulfilled, (state, action) => {
        state.roles = action.payload;
        state.isRoleLoading = false;
        state.error = false;
      })
      .addCase(getRoleByName.pending, (state, action) => {
        state.error = null;
        state.isRoleLoading = true;
      })
      .addCase(getMyRole.pending, (state, action) => {
        state.error = null;
        state.isRoleLoading = true;
      })
      .addCase(fetchRolesList.pending, (state, action) => {
        state.error = null;
        state.isRoleLoading = true;
      })
      .addCase(getProviders.fulfilled, (state, action) => {
        state.providers = action.payload;
        state.isProvidersLoading = false;
      })
      .addCase(getProviders.pending, (state, action) => {
        state.isProvidersLoading = true;
      })
      .addCase(getProviders.rejected, (state, action) => {
        state.isProvidersLoading = false;
      })
      .addCase(createProvider.fulfilled, (state, action) => {
        state.createProviderSuccess = true;
      })
      .addCase(createProvider.pending, (state, action) => {
        state.createProviderSuccess = false;
      })
      .addCase(createProvider.rejected, setError)
      .addCase(updateProvider.fulfilled, (state, action) => {
        state.updateProviderSuccess = true;
      })
      .addCase(updateProvider.pending, (state, action) => {
        state.updateProviderSuccess = false;
      })
      .addCase(updateProvider.rejected, setError)
      .addCase(deleteProvider.fulfilled, (state, action) => {
        state.deleteProviderSuccess = true;
      })
      .addCase(deleteProvider.pending, (state, action) => {
        state.deleteProviderSuccess = false;
      })
      .addCase(deleteProvider.rejected, setError)
      .addCase(getRoleByName.rejected, setError)
      .addCase(fetchRolesList.rejected, setError)
      .addCase(updateRoleByName.rejected, setError)
      .addCase(getMyRole.rejected, (state, action) => {
        state.status = false;
        state.isRoleLoading = false;
        state.error = action.payload?.response?.data?.message;
      })
      .addCase(fetchRolesPermissions.fulfilled, setPermissions)
      .addCase(invitePersonnel.fulfilled, (state, action) => {
        state.invitePersonnelSuccess = true;
      })
      .addCase(fetchPersonnelList.fulfilled, (state, action) => {
        state.error = null;
        state.isPersonnelLoading = false;
        state.personnel = action.payload;
      })
      .addCase(updatePerson.fulfilled, (state, action) => {
        state.error = null;
        state.updatePersonSuccess = true;
      })
      .addCase(deletePersonnel.fulfilled, (state, action) => {
        state.error = null;
        state.deletePersonSuccess = true;
      })
      .addCase(deleteRoleByName.fulfilled, (state, action) => {
        state.error = null;
      })
      .addCase(deleteRoleByName.pending, (state, action) => {
        state.error = null;
      })
      .addCase(deletePersonnel.rejected, setError)
      .addCase(deleteRoleByName.rejected, setError)
      .addCase(updatePerson.rejected, setError)
      .addCase(invitePersonnel.rejected, setError)
      .addCase(fetchPersonnelList.pending, (state, action) => {
        state.error = null;
        state.isPersonnelLoading = true;
      })
      .addCase(fetchPersonnelList.rejected, (state, action) => {
        state.error = true;
        state.isPersonnelLoading = false;
      })
      .addCase(confirmInvite.rejected, setError);
  }
});

export default appRoles.reducer;
