export default {
  meEndpoint: "/users/me",
  signupEndpoint: "/auth/register",
  loginEndpoint: "/auth/login",
  selectPassword: "/auth/new-password",
  logoutEndpoint: "/auth/logout",
  verifyEmailEndpoint: "/auth/verify-email",
  resendEmailEndpoint: "/auth/resend-email",
  forgotPasswordEndpoint: "/auth/forgot-password",
  resetPasswordEndpoint: "/auth/reset-password/",
  storageTokenKeyName: "accessToken",
  storageJwtKey: "jwt",
  onTokenExpiration: "refreshToken" // logout | refreshToken
};
