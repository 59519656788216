// ** Next Imports

import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

import Head from "next/head";
import { Router, useRouter } from "next/router";

// ** Store Imports
import { Provider } from "react-redux";
import { store } from "src/store";

// ** Loader Import
import NProgress from "nprogress";

// ** Emotion Imports
import { CacheProvider } from "@emotion/react";

// ** Config Imports
import { defaultACLObj } from "src/configs/acl";
import "src/configs/i18n";
import themeConfig from "src/configs/themeConfig";

// ** Fake-DB Import
import "src/@fake-db";

// ** Third Party Import
import { Toaster } from "react-hot-toast";

// ** Component Imports
import AclGuard from "@core/components/auth/AclGuard";
import AuthGuard from "@core/components/auth/AuthGuard";
import GuestGuard from "@core/components/auth/GuestGuard";
import WindowWrapper from "@core/components/window-wrapper";
import ThemeComponent from "@core/theme/ThemeComponent";
import UserLayout from "src/layouts/UserLayout";

// ** Spinner Import
import Spinner from "@core/components/spinner";

// ** Contexts
import { SettingsConsumer, SettingsProvider } from "@core/context/settingsContext";
import { AuthProvider } from "src/context/AuthContext";

// ** Styled Components
import ReactHotToast from "@core/styles/libs/react-hot-toast";

// ** Utils Imports
import { createEmotionCache } from "@core/utils/create-emotion-cache";

// ** Prismjs Styles
import "prismjs";
import "prismjs/components/prism-jsx";
import "prismjs/components/prism-tsx";
import "prismjs/themes/prism-tomorrow.css";

// ** React Perfect Scrollbar Style
import "react-perfect-scrollbar/dist/css/styles.css";
import "src/iconify-bundle/icons-bundle-react";

// ** Global css styles
import "../../styles/globals.css";

const clientSideEmotionCache = createEmotionCache();

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on("routeChangeStart", () => {
    NProgress.start();
  });
  Router.events.on("routeChangeError", () => {
    NProgress.done();
  });
  Router.events.on("routeChangeComplete", () => {
    NProgress.done();
  });
}

const Guard = ({ children, authGuard, guestGuard }) => {
  // console.log('authGuard', authGuard);
  // console.log('guestGuard', guestGuard);
  // console.log('children', children);

  if (guestGuard && !authGuard) {
    return <GuestGuard fallback={<Spinner />}>{children}</GuestGuard>;
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>;
  } else if (guestGuard && authGuard) {
    return <>{children}</>;
  } else {
    return <AuthGuard fallback={<Spinner />}>{children}</AuthGuard>;
  }
};

// ** Configure JSS & ClassName
const App = props => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const router = useRouter();

  // Variables
  const contentHeightFixed = Component.contentHeightFixed ?? false;

  const getLayout =
    Component.getLayout ?? (page => <UserLayout contentHeightFixed={contentHeightFixed}>{page}</UserLayout>);
  const setConfig = Component.setConfig ?? undefined;
  const authGuard = Component.authGuard ?? true;
  const guestGuard = Component.guestGuard ?? false;
  const aclAbilities = Component.acl ?? defaultACLObj;
  if (router.asPath == "/setup-account/") {
    return (
      <Provider store={store}>
        <CacheProvider value={emotionCache}>
          <Head>
            <title>{`My ${themeConfig.templateName}`}</title>

            <meta
              name='description'
              content={`${themeConfig.templateName} – Gain insights into your online presence with our comprehensive digital marketing dashboard, offering real-time analytics and performance metrics to optimize your campaigns and drive results.`}
            />
            <meta name='keywords' content='digital marketing, MyVigorant , invigorate your business' />
            <meta name='viewport' content='initial-scale=1, width=device-width' />
            <meta property='og:image' content='https://my.vigorant.com/images/lp/Overview.png' />
          </Head>
          <AuthProvider guestGuard={guestGuard}>
            <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
              <SettingsConsumer>
                {({ settings }) => {
                  return (
                    <ThemeComponent settings={settings}>
                      <WindowWrapper>
                        <Guard authGuard={authGuard} guestGuard={guestGuard}>
                          <AclGuard aclAbilities={aclAbilities} guestGuard={guestGuard}>
                            <Component {...pageProps} />
                          </AclGuard>
                        </Guard>
                      </WindowWrapper>
                      <ReactHotToast>
                        <Toaster position={settings.toastPosition} toastOptions={{ className: "react-hot-toast" }} />
                      </ReactHotToast>
                    </ThemeComponent>
                  );
                }}
              </SettingsConsumer>
            </SettingsProvider>
          </AuthProvider>
        </CacheProvider>
      </Provider>
    );
  }

  return (
    <Provider store={store}>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>{`My ${themeConfig.templateName}`}</title>
          <meta
            name='description'
            content={`${themeConfig.templateName} – Gain insights into your online presence with our comprehensive digital marketing dashboard, offering real-time analytics and performance metrics to optimize your campaigns and drive results.`}
          />
          <meta name='keywords' content='digital marketing, My vigorant , dashboard' />
          <meta name='viewport' content='initial-scale=1, width=device-width' />
          <meta property='og:image' content='https://my.vigorant.com/images/lp/Overview.png' />
        </Head>

        <AuthProvider guestGuard={guestGuard}>
          <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
            <SettingsConsumer>
              {({ settings }) => {
                return (
                  <ThemeComponent settings={settings}>
                    <WindowWrapper>
                      <Guard authGuard={authGuard} guestGuard={guestGuard}>
                        <AclGuard aclAbilities={aclAbilities} guestGuard={guestGuard}>
                          {getLayout(<Component {...pageProps} />)}
                        </AclGuard>
                      </Guard>
                    </WindowWrapper>
                    <ReactHotToast>
                      <Toaster position={settings.toastPosition} toastOptions={{ className: "react-hot-toast" }} />
                    </ReactHotToast>
                  </ThemeComponent>
                );
              }}
            </SettingsConsumer>
          </SettingsProvider>
        </AuthProvider>
      </CacheProvider>
    </Provider>
  );
};

export default App;
